import React, { useEffect } from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import { DefaultSeo } from 'next-seo';
import * as Sentry from '@sentry/node';

import { ThemeProvider } from 'styled-components';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Provider as StyletronProvider } from 'styletron-react';
import { styletron } from '../styletron';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Layout from '../components/Layout';
import SEO from '../../next-seo.config';
import { MUI_THEME, THEME } from '../utils/constants';
import { pageview } from '../utils/analytics';
import { GlobalStyle } from '../globalStyles';
import { AuthProvider } from '../contexts/auth/auth.context';
import { NotificationProvider } from '../contexts/notification/notification.context';

Sentry.init({
  enabled: true,
  dsn: process.env.SENTRY_DSN,
});

const Muitheme = createMuiTheme(MUI_THEME);

const handleRouteChangeStart = (url: string) => {
  NProgress.start();
  pageview(url);
};

const handleRouteChangeComplete = () => NProgress.done();

const handlerouteChangeError = (err: any) => {
  Sentry.captureException(err);
  NProgress.done();
};

function MyApp({ Component, pageProps, err }: any) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);
    Router.events.on('routeChangeError', handlerouteChangeError);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
      Router.events.off('routeChangeError', handlerouteChangeError);
    };
  }, []);

  return (
    <MuiThemeProvider theme={Muitheme}>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <StyletronProvider value={styletron}>
        <ThemeProvider theme={THEME}>
          <NotificationProvider>
            <AuthProvider>
              <Layout>
                <DefaultSeo {...SEO} />
                <Component {...pageProps} err={err} />
              </Layout>
              <GlobalStyle />
            </AuthProvider>
          </NotificationProvider>
        </ThemeProvider>
      </StyletronProvider>
    </MuiThemeProvider>
  );
}

export default MyApp;
