import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import Container from '@material-ui/core/Container';
import { useMediaQuery, Typography } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BREAKPOINTS, FOOTER_LISTINGS_SECTIONS } from 'src/utils/constants';

type IStyled = {
  isMobile?: boolean;
};
const FooterSection = styled.footer`
  background: #fafafa;
  color: ${({ theme }) => theme.colors.secondary};
`;
const FooterContent = styled(Container)<IStyled>`
  display: flex !important;
  padding-bottom: 60px;
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
`;
const ListingSection = styled.div`
  flex-basis: 40%;
  padding: 60px 15px 0 0;
  .MuiGrid-container {
    width: 80% !important;
  }
`;

const PageLinksFooter = () => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);

  return (
    <FooterSection>
      <FooterContent maxWidth="lg" isMobile={isMobile}>
        <ListingSection>
          <Typography variant="h5" component="h5" gutterBottom>
            Ads By Location:
          </Typography>
          <Grid container spacing={0}>
            {FOOTER_LISTINGS_SECTIONS.LOCATIONS.map((location, key) => {
              return (
                <Grid key={key} item xs={12} sm={12}>
                  <Box color="secondary.main" p={1}>
                    <Link
                      href="/rent-ads/[location]"
                      as={`/rent-ads/${location.slug}`}
                      passHref
                      prefetch={false}
                    >
                      <MuiLink
                        color="secondary"
                        // style={{ color: 'white' }}
                      >{`Rental Ads in ${location.name}`}</MuiLink>
                    </Link>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </ListingSection>
        {FOOTER_LISTINGS_SECTIONS.CATEGORIES.map((category) => {
          return (
            <ListingSection key={category.slug}>
              <Typography variant="h5" component="h5" gutterBottom>
                {`Ads By ${category.name}:`}
              </Typography>
              <Grid>
                {FOOTER_LISTINGS_SECTIONS.LOCATIONS.map((location, key) => {
                  return (
                    <Grid key={key} item xs={12} sm={12}>
                      <Box color="secondary.main" p={1}>
                        <Link
                          href="/rent-ads/[location]/[category-slug]"
                          as={`/rent-ads/${location.slug}/${category.slug}`}
                          passHref
                          prefetch={false}
                        >
                          <MuiLink
                            color="secondary"
                            // style={{ color: 'white' }}
                          >{`${category.name} in ${location.name}`}</MuiLink>
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </ListingSection>
          );
        })}
      </FooterContent>
    </FooterSection>
  );
};
export default PageLinksFooter;
