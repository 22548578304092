export default {
  facebook: {
    appId: '3214853175221756',
  },
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://rentable.pk/',
    site_name: 'Rentable',
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};
