import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

const ListingCard = styled.div`
  border-radius: 8px;
`;
const StyledSkeleton = styled(Skeleton)`
  border-radius: 8px;
`;

type ListingCardSkeleton = {
  className?: string;
};
const ListingCardSkeleton = ({ className }: ListingCardSkeleton) => {
  return (
    <ListingCard className={className}>
      <StyledSkeleton variant="rect" width={300} height={200} />
      <Skeleton variant="text" width={200} height={30} />
      <Skeleton variant="text" width={250} height={30} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={100} height={30} />
        <Skeleton variant="text" width={30} height={30} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={150} height={30} />
        <Skeleton variant="text" width={30} height={30} />
      </div>
    </ListingCard>
  );
};

export default ListingCardSkeleton;
