import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Link from 'next/link';
import styled from 'styled-components';
// import Box from '@material-ui/core/Box';
// import CircularProgress from '@material-ui/core/CircularProgress';
import MuiButton from '@material-ui/core/Button';
// import { useAuthentication } from 'src/contexts/auth/auth.context';
import Slider from 'react-slick';
import { IListings } from 'src/interface';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// import RentNowCard from '../../AdCard/RentNowCard';

import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from 'src/utils/constants';
import Card from '../../ListingCard';
import ListingCardSkeleton from '../../ListingCard/AdCardSkeleton';
// import TipsCard from '../../AdCard/TipsCard';

const ListingCard = styled(Card)`
  max-width: 350px;
  /* margin: 10px 10px; */
  margin: 5px;
  padding: 5px;
  @media (max-width: 450px) {
    /* width: 300px; */
    margin: 10px 0px;
    margin-right: 10px;
  }
`;
const SectionContent = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  .slick-next {
    right: -15px;
    &:hover {
      bottom: 3px !important;
      box-shadow: rgba(46, 62, 72, 0.12) 0px 2px 8px 0px, rgba(46, 62, 72, 0.12) 0px 2px 4px 0px !important;
    }
  }
  .slick-prev {
    left: -15px;
  }
  .slick-disabled {
    display: none !important;
    background: gray !important;
    color: white !important;
    &:hover {
      background: gray !important;
      color: white !important;
    }
  }
`;
export const LoadingSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 100px 0;
`;
export const StyledGrid = styled(Grid)`
  h1 {
    text-align: center;
  }
  a {
    text-decoration: none;
  }
`;
// const Button = styled(MuiButton)``;
const NextArrow = styled(ArrowForwardIosIcon)`
  position: relative;
  font-size: 13px !important;
  left: 0px;
`;
const PrevArrow = styled(ArrowBackIosIcon)`
  position: relative;
  font-size: 13px !important;
  left: 2px;
`;
const ArrowContainer = styled.div`
  box-shadow: rgba(46, 62, 72, 0.12) 0px 0px 2px 0px, rgba(46, 62, 72, 0.12) 0px 2px 4px 0px;
  color: ${({ theme }) => theme.colors.primary} !important;
  border: 1px solid rgba(46, 62, 72, 0.12);
  bottom: 0px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0px;
  width: 40px;
  z-index: 10;
  background: white !important;
  border-radius: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &:before {
    display: none;
  }
`;
const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledListingCardSkeleton = styled(ListingCardSkeleton)<any>`
  margin: 0 5px;
`;

type ListingsSection = {
  listings: IListings | null;
  isWanted?: boolean;
  isLoading: boolean;
};

const ListingsSection = ({ listings, isWanted, isLoading = true }: ListingsSection) => {
  // const [authState, dispatchToAuth] = useAuthentication();
  // const { isAuthenticated } = authState;
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);

  const SliderSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 200,
    centerPadding: '10px',
    className: 'center',
    centerMode: isMobile,
    nextArrow: (
      <ArrowContainer>
        <NextArrow />
      </ArrowContainer>
    ),
    prevArrow: (
      <ArrowContainer>
        <PrevArrow />
      </ArrowContainer>
    ),

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <SectionContent>
      <div style={{ width: '100%' }}>
        {(!isLoading && listings && (
          <Slider {...SliderSettings}>
            {listings.data.map((listing) => {
              return (
                <ListingCard key={listing.id} listingData={listing} isWantedListing={isWanted} />
              );
            })}
          </Slider>
        )) || (
          <SkeletonContainer>
            <StyledListingCardSkeleton />
            <StyledListingCardSkeleton />
            <StyledListingCardSkeleton />
            <StyledListingCardSkeleton />
          </SkeletonContainer>
        )}
      </div>

      {/* {(listings &&
          listings.data.map((listing, key) => {
            if (key % 10 === 0 && key !== 0) {
              return (
                <>
                  <StyledGrid key={key} item xs={12} sm={3}>
                    {(isAuthenticated && (
                      <Link href="/post-listing">
                        <a href="/post-listing">
                          <Box p={1} style={{ height: '100%' }}>
                            <TipsCard>
                              <Button variant="contained" color="secondary">
                                Start Renting
                              </Button>
                            </TipsCard>
                          </Box>
                        </a>
                      </Link>
                    )) || (
                      <Box p={1} style={{ height: '100%' }}>
                        <RentNowCard>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              dispatchToAuth({ type: 'SWITCH_AUTH_MODAL', payload: true })
                            }
                          >
                            Start Renting
                          </Button>
                        </RentNowCard>
                      </Box>
                    )}
                  </StyledGrid>
                  <Grid key={key} item xs={12} sm={3}>
                    <Box p={1}>
                      <ListingCard key={listing.id} listingData={listing} />
                    </Box>
                  </Grid>
                </>
              );
            }
            return (
              <Grid key={key} item xs={12} sm={3}>
                <Box p={1}>
                  <ListingCard key={listing.id} listingData={listing} />
                </Box>
              </Grid>
            );
          })) || (
          <LoadingSection>
            <CircularProgress />
          </LoadingSection>
        )} */}
    </SectionContent>
  );
};

export default ListingsSection;
