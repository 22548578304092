import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import Container from '@material-ui/core/Container';
import { useMediaQuery } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import { FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaRssSquare } from 'react-icons/fa';

import LinksFooter from 'src/components/Footer/PageLinks';
// import Subscription from './Subscription';
import { BREAKPOINTS, SOCIAL_LINKS } from '../../utils/constants';
import { AppsBanner } from '../LandingPage/Sections';
import { LogoSection, StyledRentableLogo, StyledRentableLogoEmblem } from '../Header';

const FooterSection = styled.footer`
  background: ${({ theme }) => theme.colors.secondary};
  color: white;
`;
// const SubscriptionSection = styled(Subscription)``;
const FooterContent = styled(Container)`
  display: flex !important;
  flex-direction: column !important;
  padding-top: 30px;
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
`;
const InfoSection = styled.div`
  //flex-basis: 60%;
  display: flex;
  //align-items: flex-start;
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
  align-items: ${({ isMobile }) => (isMobile && 'flex-start') || 'center'};
  justify-content: ${({ isMobile }) => (isMobile && 'flex-start') || 'center'};
  //padding: 60px 15px 0 0;
`;
const RentableInfo = styled.div`
  flex-basis: 60%;
`;
// const ListingSection = styled.div`
//   flex-basis: 40%;
//   padding: 60px 15px 0 0;
//   .MuiGrid-container {
//     width: 80% !important;
//   }
// `;
const Logo = styled.img``;
const Info = styled.p`
  font-size: 15px;
  padding: 10px 0;
  line-height: 1.4;
`;
// const Social = styled.div`
//   display: flex;
//   flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
//   justify-content: space-between;
//   width: 100%;
// `;
const Follows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: ${({ isMobile }) => (isMobile && '100%') || '40%'};
`;
const FollowHeading = styled.h1``;
const FooterNav = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-basis: 70%;
  list-style: none;
  padding-left: 0;
  margin: 0;
`;
const FooterNavItem = styled.li`
  :not(:first-child) {
    margin-left: 15px;
  }
`;
const FooterBottom = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
  align-items: center;
  padding: 20px 0;
`;
const SocialList = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0;
`;
const SocialItem = styled.li``;
const SocialLink = styled.a`
  margin-left: 10px;
`;
const Copyrights = styled.div`
  flex-basis: 30%;
  font-size: 13px;
  margin-top: ${({ isMobile }) => (isMobile && '20px') || '0px'};
  //margin-top: 20px;
`;

// Icons
const FacebookIcon = styled(FaFacebookSquare)`
  color: white;
  font-size: 35px;
`;
const TwitterIcon = styled(FaTwitterSquare)`
  color: white;
  font-size: 35px;
`;
const InstagramIcon = styled(FaInstagramSquare)`
  color: white;
  font-size: 35px;
`;
const RSSFeedIcon = styled(FaRssSquare)`
  color: white;
  font-size: 35px;
`;

const Footer = () => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  // const TrendingLocations = useTrendingLocations();

  return (
    <>
      <LinksFooter />
      <AppsBanner />
      <FooterSection>
        {/* <SubscriptionSection /> */}
        <Divider orientation="horizontal" />
        <FooterContent maxWidth="lg" isMobile={isMobile}>
          <InfoSection isMobile={isMobile}>
            <RentableInfo>
              <LogoSection>
                <StyledRentableLogoEmblem fill="white" />
                <StyledRentableLogo fill="white" />
              </LogoSection>
              <Info>
                Just another classified website, where customers can turn their liabilities into
                paying assets by renting out their liable stuff with ability to secure their rental
                transactions with easy rental agreements.
              </Info>
            </RentableInfo>

            <Follows isMobile={isMobile}>
              <FollowHeading>Follow Us:</FollowHeading>
              <SocialList>
                <SocialItem>
                  <SocialLink target="_blank" href={SOCIAL_LINKS.FACEBOOK}>
                    <FacebookIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink target="_blank" href={SOCIAL_LINKS.TWITTER}>
                    <TwitterIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink target="_blank" href={SOCIAL_LINKS.INSTAGRAM}>
                    <InstagramIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink target="_blank" href={SOCIAL_LINKS.RSSFEED}>
                    <RSSFeedIcon />
                  </SocialLink>
                </SocialItem>
              </SocialList>
            </Follows>
          </InfoSection>
          <FooterBottom isMobile={isMobile}>
            <FooterNav>
              <FooterNavItem>
                <Link href="/policy/terms-of-use" passHref prefetch={false}>
                  <MuiLink color="primary">Terms of Use</MuiLink>
                </Link>
              </FooterNavItem>
              <FooterNavItem>
                <Link href="/policy/privacy-policy" passHref prefetch={false}>
                  <MuiLink color="primary">Privacy Policy</MuiLink>
                </Link>
              </FooterNavItem>
              <FooterNavItem>
                <Link href="/policy/disclaimer-policy" passHref prefetch={false}>
                  <MuiLink color="primary">Disclaimer Policy</MuiLink>
                </Link>
              </FooterNavItem>
              <FooterNavItem>
                <MuiLink href="https://blog.rentable.pk/" color="primary">
                  Blog
                </MuiLink>
              </FooterNavItem>
            </FooterNav>
            <Copyrights isMobile={isMobile}>
              2020, All Rights Reserved -{' '}
              <MuiLink color="primary" href="/">
                Rentable Pakistan
              </MuiLink>
            </Copyrights>
          </FooterBottom>
        </FooterContent>
      </FooterSection>
    </>
  );
};
export default Footer;
