import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import styled from 'styled-components';

import Header from '../Header';
import Footer from '../Footer';
import AdBanner from '../Adsense';

const Content = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.light.primary};
`;

type TProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: TProps) => {
  const router = useRouter();
  // const dispatch = useDispatch();
  const { pathname } = router;
  // const cookiesConsent = useCookiesConsent();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {pathname !== '/post-listing' &&
        pathname !== '/post-wanted-listing' &&
        !pathname.match(/dashboard/g) && <Header />}
      <Content>{children}</Content>
      {/* <AdBanner /> */}
      {pathname !== '/post-listing' &&
        pathname !== '/post-wanted-listing' &&
        !pathname.match(/dashboard/g) && <Footer />}
      {/* {!cookiesConsent && (
        <CookieConsent
          onAccept={() => {
            dispatch(CommonActionCreators.setCookiesConsent(true));
          }}
          style={{ background: '#363b4d' }}
          buttonStyle={{
            background: '#3fb865',
            color: '#fff',
            fontSize: '15px',
            fontFamily: 'inherit',
          }}
        >
          We use cookies to personalise content and ads, to provide social media features and to
          analyse our traffic. We also share information about your use of our site with our social
          media, advertising and analytics partners
        </CookieConsent>
      )} */}
    </>
  );
};

export default Layout;
