export {};
declare global {
  interface Window {
    gtag: any;
    FB: any;
  }
}
export const pageview = (url: string) => {
  if (window.gtag) {
    window.gtag('config', process.env.GA_ID, {
      page_path: url,
    });
  }
  if (window.FB) {
    window.FB.AppEvents.logPageView();
  }
};

export const setUserId = ({ id }: { id: string }) => {
  if (window.gtag) {
    window.gtag('config', process.env.GA_ID, { user_id: id });
  }
};

export const GAEvent = (props: any) => {
  const { action, category, label, value } = props;
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};
