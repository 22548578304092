import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle` 
  html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    padding: 0;
    font-size: 13px;
    background-color: #fff;
    font-family: 'Quicksand',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
      sans-serif;
  }
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: #3fb865;

    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;

    width: 100%;
    height: 3px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #3fb865, 0 0 5px #3fb865;
    opacity: 1;

    transform: rotate(3deg) translate(0px, -4px);
  }
    .MuiPopover-paper {
    border-radius: 0 !important;
  }
`;
